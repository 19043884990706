/*
| Developed by Dirupt
| Filename : loading.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { SplashScreen } from '@/components/loading-screen'

/*
|--------------------------------------------------------------------------
| Loading
|--------------------------------------------------------------------------
*/
export default function Loading() {
	return <SplashScreen />
}
