/*
| Developed by Dirupt
| Filename : logo.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Link from '@mui/material/Link'
import { styled, useTheme } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid, { GridProps } from '@mui/material/Grid'
import { RouterLink } from '@/routes/components'
import { pxToRem } from '@/theme/typography'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LogoProps extends BoxProps {
	disabledLink?: boolean
	withText?: 'default' | 'top'
	gridProps?: GridProps
	mobileOnly?: boolean
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SHPVTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontWeight: 900,
	lineHeight: 1.2,
	fontSize: pxToRem(14),
}))

const Baseline = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontWeight: 400,
	lineHeight: 1.2,
	fontSize: pxToRem(14),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const Logo = React.forwardRef<HTMLDivElement, LogoProps>(
	({ disabledLink = false, sx, withText, gridProps, mobileOnly = false, ...other }, ref) => {
		const theme = useTheme()

		const logo = (
			<Box
				ref={ref}
				component="div"
				sx={{
					width: 40,
					height: 40,
					display: 'inline-flex',
					...sx,
				}}
				{...other}
			>
				<svg width="100%" height="100%" viewBox="0 0 512 512">
					<linearGradient
						id="SVGID_1_"
						gradientUnits="userSpaceOnUse"
						x1="509.7079"
						y1="379.7804"
						x2="211.3792"
						y2="-15.944"
					>
						<stop offset="8.942884e-02" style={{ stopColor: theme.palette.primary.main }} />
						<stop offset="1" style={{ stopColor: theme.palette.secondary.main }} />
					</linearGradient>
					<path
						fill="url(#SVGID_1_)"
						d="M410.5,456.2c11.9-80.7-29.2-130.5-79.3-175.5c-34.1-30.7-69.3-60.2-103.3-91
	c-19.5-17.7-36.4-37.9-46.4-62.8C157.7,67.5,193.3,7.2,257.4,6.7c106.1-0.9,200.1,61.1,239,166.5
	C533.9,274.9,500.3,391.5,410.5,456.2z"
					/>
					<linearGradient
						id="SVGID_00000180343725703193646340000008210279863140522634_"
						gradientUnits="userSpaceOnUse"
						x1="307.3034"
						y1="532.3692"
						x2="8.9746"
						y2="136.6449"
					>
						<stop offset="8.942884e-02" style={{ stopColor: theme.palette.primary.main }} />
						<stop offset="1" style={{ stopColor: theme.palette.secondary.main }} />
					</linearGradient>
					<path
						fill="url(#SVGID_00000180343725703193646340000008210279863140522634_)"
						d="M105.8,52.6c-13.7,80.5,26.4,131.1,75.4,177.2
	c33.4,31.4,68,61.7,101.3,93.2c19.1,18.1,35.5,38.7,45,63.8c22.6,59.9-14.3,119.4-78.4,118.6c-106.1-1.4-198.7-65.4-235.3-171.6
	C-21.5,231.2,14.6,115.3,105.8,52.6z"
					/>
				</svg>
			</Box>
		)

		const logoWithText = (
			<Grid
				container
				gap={2}
				alignItems={'center'}
				direction={withText === 'top' ? 'column' : 'row'}
				textAlign={withText === 'top' ? 'center' : 'left'}
				width={'auto'}
				{...gridProps}
			>
				<Grid item>{logo}</Grid>
				<Grid item>
					<SHPVTitle>SHPV FRANCE</SHPVTitle>
					{!mobileOnly ? <Baseline>Infrastructure & Infogérance</Baseline> : null}
				</Grid>
			</Grid>
		)

		if (disabledLink) {
			return withText ? logoWithText : logo
		}

		return (
			<Link component={RouterLink} href="/" sx={{ display: 'contents' }} aria-label={'Logo de SHPV France'}>
				{withText ? logoWithText : logo}
			</Link>
		)
	},
)

Logo.displayName = 'Logo'

export default Logo
