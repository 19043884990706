/*
| Developed by Dirupt
| Filename : router-link.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Link, { LinkProps } from 'next/link'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const RouterLink = React.forwardRef<HTMLAnchorElement, LinkProps & { target?: string }>(({ ...other }, ref) => (
	<Link ref={ref} style={{ textDecoration: 'none' }} {...other} />
))

RouterLink.displayName = 'RouterLink'
